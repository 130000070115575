// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

// Hooks
import { useAtomize } from '../../../hooks/useAtomize'

export const Spinner = () => {
  const { Div } = useAtomize()
  return (
    <Div className="spinner-wrapper">
      <FontAwesomeIcon
        icon={icon({ name: 'circle-notch' })}
        spin
        size="4x"
        className="spinner"
        color="#3D3D3D"
      />
    </Div>
  )
}
