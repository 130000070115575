export const PasswordFAQ = [
  {
    title: 'What is a password generator?',
    content:
      'A password generator is a tool that automatically generates a password based on guidelines that you set to create strong and unpredictable passwords for each of your accounts.',
  },
  {
    title: 'Why should I use a password generator?',
    content:
      'Using a password generator is a great way to create strong and unpredictable passwords for each of your accounts. This is important because if you use the same password for multiple accounts, and one of those accounts is compromised, then all of your accounts are compromised.',
  },
  {
    title: 'How do I use a password generator?',
    content:
      'To use a password generator, simply select the options that you want to use to generate your password, and then click the "Generate Password" button. You can then copy the generated password to your clipboard by clicking the "Copy Password" button.',
  },
  {
    title: 'Is it safe to generate passwords online?',
    content:
      'Yes, it is safe to generate passwords online. This is because the password generator runs entirely in your browser, and does not send any information to a server.',
  },
  {
    title: 'What is a strong password?',
    content:
      'A strong password is a password that is difficult to guess or crack. A strong password should be at least 12 characters long, and should contain a combination of uppercase letters, lowercase letters, numbers, and special characters.',
  },
  {
    title: 'Can a strong password be hacked?',
    content:
      'Creating completely uncrackable passwords is becoming a challenge. But you can stand a better chance against hackers by avoiding bad password practices. They include reusing passwords for different accounts, common keyword patterns (such as qwerty), common acronyms (such as ymca), or repeating characters (such as zzz111). Stay on track with your passwords by using our online password generator and password strength checker tools.',
  },
  {
    title: 'What is a secure password?',
    content:
      'A secure password is a password that is difficult to guess or crack, and is unique to each of your accounts. A secure password should be at least 12 characters long, and should contain a combination of uppercase letters, lowercase letters, numbers, and special characters.',
  },
  {
    title: 'What is a weak password?',
    content:
      'A weak password is a password that is easy to guess or crack. A weak password is usually short, and contains only lowercase letters or only numbers.',
  },
  {
    title: 'What is a compromised password?',
    content:
      'A compromised password is a password that has been exposed in a data breach. If you use a compromised password for any of your accounts, then that account is at risk of being compromised.',
  },
  {
    title: 'What is a data breach?',
    content:
      'A data breach is a security incident in which sensitive, protected, or confidential data is copied, transmitted, viewed, stolen, or used by an unauthorized individual.',
  },
  {
    title: 'What is a password manager?',
    content:
      'A password manager is a tool that stores your passwords in an encrypted database, and allows you to access your passwords with a single master password.',
  },
  {
    title: 'Why should I use a password manager?',
    content:
      'A password manager is a secure way to store your passwords. They’re protected with top-notch encryption and are synced across all of your devices, so you can securely access them any time, no matter where you are.',
  },
]
