// Styles
import { Link } from 'react-router-dom'
import { useAtomize } from '../../../hooks/useAtomize'
import './ContactUsPage.scss'

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function ContactUsPage() {
  const { Div } = useAtomize()
  return (
    <Div className="contact-us-container">
      <Div
        className="container"
        w={{ xs: '100%', lg: '80vw' }}
        m="auto"
        p="3em"
        flexGrow="1"
        bg="gray200"
      >
        <Link to="/" replace={true}>
          <FontAwesomeIcon icon={icon({ name: 'chevron-left' })} /> Back{' '}
        </Link>
      </Div>
    </Div>
  )
}
