import { gql } from '@apollo/client'

export const BLOG_PAGE_QUERY = gql`
  query BlogPageQuery($postId: String!) {
    blogPage(id: $postId) {
      title
      sys {
        id
        firstPublishedAt
      }
      body
      image {
        url
      }
      recommendedPostsCollection {
        items {
          sys {
            id
          }
        }
      }
      seoTags
    }
  }
`
