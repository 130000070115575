import React from 'react'
import { Link, NavLink } from 'react-router-dom'

// Styles
import './SideBar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

// Hooks
import { useAtomize } from '../../../hooks/useAtomize'

type Props = {
  onClose?: () => void
  className?: string
}

const Sidebar = ({ onClose, className }: Props) => {
  const { Div } = useAtomize()

  return (
    <Div className={`smooth-sidebar ${className}`}>
      <Div className="sidebar-inner">
        <Div className="smooth-sidebar-header">
          <ProfileContent />
        </Div>
        <Div className="smooth-sidebar-navs">
          <ul className="sidebar-nav">
            <li className="sidebar-item">
              <NavLink to="/" className="sidebar-link" onClick={onClose}>
                <FontAwesomeIcon icon={icon({ name: 'house' })} />
                <span>Home</span>
              </NavLink>
              <NavLink
                to="/conversion"
                className="sidebar-link"
                onClick={onClose}
              >
                <FontAwesomeIcon icon={icon({ name: 'pen-ruler' })} />
                <span>Unit Conversion</span>
              </NavLink>
              <NavLink
                to="/password"
                className="sidebar-link"
                onClick={onClose}
              >
                <FontAwesomeIcon icon={icon({ name: 'user-secret' })} />
                <span>Password Generator</span>
              </NavLink>
              <NavLink to="/blog" className="sidebar-link" onClick={onClose}>
                <FontAwesomeIcon icon={icon({ name: 'blog' })} />
                <span>Blog</span>
              </NavLink>
            </li>
          </ul>
        </Div>
      </Div>
    </Div>
  )
}

export default Sidebar

const profile = {
  name: 'Unit Conversion',
  avatar: '/ms-icon-310x310.png',
}

const ProfileContent = () => {
  return (
    <Link className="sidebar-media-link" to="/">
      <img
        className="avatar"
        src={profile.avatar}
        style={{ height: '40px', width: '40px' }}
        alt="profile avatar"
      />
      <div className="media-body">
        <span className="name">{profile.name}</span>
      </div>
    </Link>
  )
}

type OverlayProps = {
  className: string
  onClick: () => void
}

export const Overlay = ({ className, onClick }: OverlayProps) => {
  return <div className={className} onClick={onClick}></div>
}

type HamburguerProps = {
  isActive: boolean
  onClick: () => void
}

export const HamburgerButton = ({ isActive, onClick }: HamburguerProps) => {
  const { Button, Icon } = useAtomize()
  return (
    <Button
      height="auto"
      bg="transparent"
      textColor="white"
      hoverBg="transparent"
      onClick={onClick}
    >
      {!isActive && <Icon name={'Menu'} size="20px" />}
    </Button>
  )
}
