import React from 'react'
import { useParams, Navigate } from 'react-router-dom'
import MD from 'react-markdown'

// Styles
import './BlogPostPage.scss'

// Components
import MetaTags from '../../atoms/MetaTags/MetaTags'
import { Spinner } from '../../atoms/Spinner/Spinner'

// Hooks
import { useAtomize } from '../../../hooks/useAtomize'
import { useBoolean } from '../../../hooks/useBoolean'

// Data
import { BlogPostPageMetaData } from './BlogPostPageMetaData'

// Queries
import { useQuery } from '@apollo/client'
import {
  BlogPageQuery,
  BlogPageQueryVariables,
} from './queries/__generated__/BlogPageQuery'
import { BLOG_PAGE_QUERY } from './queries/BlogPageQuery'

export const BlogPostPage = () => {
  const { Div, Notification, Icon, Text } = useAtomize()
  const { id } = useParams()
  const postId = id ? id : 'fdxXlXyCh12vUYEl9LIEo'

  const { data, loading, error } = useQuery<
    BlogPageQuery,
    BlogPageQueryVariables
  >(BLOG_PAGE_QUERY, { variables: { postId: postId } })
  const {
    value: isNotificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useBoolean(false)

  if (loading) {
    return (
      <Div className="spinner-centered blog-wrapper">
        <Spinner />
      </Div>
    )
  }

  if (error || !data || !data.blogPage) {
    return <Navigate to="/blog/" />
  }

  const { blogPage } = data
  const { title, body, recommendedPostsCollection } = data?.blogPage || {}
  console.log(recommendedPostsCollection)

  return (
    <>
      <MetaTags {...BlogPostPageMetaData(title, body)} />
      <Notification
        bg="danger100"
        textColor="danger800"
        isOpen={isNotificationOpen}
        onClose={setNotificationClosed}
        prefix={
          <Icon
            name="Close"
            color="danger800"
            size="18px"
            m={{ r: '0.5rem' }}
          />
        }
      >
        Something went wrong
      </Notification>
      <Div className="blog-post-wrapper">
        {blogPage && (
          <>
            <Text
              tag="h1"
              textSize="display2"
              textWeight="500"
              m={{ b: '1rem' }}
            >
              {title}
            </Text>
            {body && <MD children={body} />}
          </>
        )}
      </Div>
    </>
  )
}

export default BlogPostPage
