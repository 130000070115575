export const theme = {
  transition: {
    custom: 'transform 0.8s ease-in-out',
  },
  colors: {
    brand100: '#F9F8FC',
    brand200: '#F3F1FA',
    brand300: '#E9E6F6',
    brand400: '#D2CCEC',
    brand500: '#BCB3E2',
    brand600: '#9C8FD6',
    brand700: '#6F5CC3',
    brand800: '#553EB5',
    brand900: '#382683',
  },
}
