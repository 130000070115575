// Styles
import { Link } from 'react-router-dom'
import { useAtomize } from '../../../hooks/useAtomize'
import './PrivacyPolicyPage.scss'

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function PrivacyPolicyPage() {
  const { Div } = useAtomize()
  return (
    <Div flexGrow="1">
      <Link to="/" replace={true}>
        <FontAwesomeIcon icon={icon({ name: 'chevron-left' })} /> Back{' '}
      </Link>
      <br />
      <br />
      Privacy Policy for UnitConversion.page
      <br />
      <br />
      Effective Date: July 15, 2023 <br />
      <br />
      This Privacy Policy describes how UnitConversion.page collects, uses, and
      protects the personal information you provide while using our website.
      Please read this policy carefully to understand our practices regarding
      your personal data.
      <br />
      <br />
      1. Information We Collect:
      <br />
      <br />
      1.1 Personal Information:
      <br />
      When you use UnitConversion.page, we may collect certain personal
      information from you, such as your name and email address, if you
      voluntarily provide it to us. This information is collected when you
      contact us via email or submit feedback through our website.
      <br />
      <br />
      1.2 Usage Information:
      <br />
      We may also collect non-personal information about your usage of the
      UnitConversion.page website. This includes information such as your IP
      address, browser type, device type, operating system, and the pages you
      visit. We use this information to analyze trends, administer the website,
      track user movements, and gather demographic information for aggregate
      use.
      <br />
      <br />
      2. Use of Collected Information:
      <br />
      <br />
      2.1 Personal Information:
      <br />
      We may use the personal information you provide to respond to your
      inquiries, feedback, or requests. Your email address may be used to
      communicate with you regarding the services provided on
      UnitConversion.page.
      <br />
      <br />
      2.2 Usage Information:
      <br />
      The usage information collected helps us improve the functionality and
      content of UnitConversion.page. It allows us to understand how users
      interact with the website, identify areas for improvement, and provide a
      better user experience. This information may be analyzed in an aggregated
      and anonymized manner to generate statistical data.
      <br />
      <br />
      3. Cookies and Similar Technologies:
      <br />
      <br />
      3.1 Cookies:
      <br />
      UnitConversion.page may use cookies or similar technologies to enhance
      your browsing experience. Cookies are small text files stored on your
      device that help us recognize you and provide personalized features. You
      can modify your browser settings to decline cookies if you prefer.
      <br />
      <br />
      4. Data Security:
      <br />
      <br />
      4.1 Protection Measures:
      <br />
      UnitConversion.page takes reasonable measures to protect your personal
      information from unauthorized access, alteration, or disclosure. However,
      please note that no method of data transmission or storage is completely
      secure. We strive to protect your personal data but cannot guarantee its
      absolute security.
      <br />
      <br />
      5. Third-Party Links:
      <br />
      <br />
      5.1 External Websites:
      <br />
      UnitConversion.page may contain links to external websites. Please note
      that we have no control over the privacy practices or content of these
      websites. We recommend reviewing the privacy policies of any external
      sites you visit.
      <br />
      <br />
      6. Children's Privacy:
      <br />
      <br />
      6.1 Age Limitation:
      <br />
      UnitConversion.page is not intended for children under the age of 13. We
      do not knowingly collect personal information from children. If we become
      aware that personal information has been collected from a child under the
      age of 13, we will take steps to delete it promptly.
      <br />
      <br />
      7. Changes to the Privacy Policy:
      <br />
      <br />
      7.1 Policy Updates:
      <br />
      UnitConversion.page may update this Privacy Policy periodically to reflect
      changes in our practices or applicable laws. We will notify you of any
      significant changes by posting the updated policy on the website.
      <br />
      <br />
      8. Contact Us:
      <br />
      <br />
      8.1 Questions or Concerns:
      <br />
      If you have any questions or concerns about this Privacy Policy or the
      practices of UnitConversion.page, please contact us at
      bruno.talarico@gmail.com.
      <br />
      <br />
      By using UnitConversion.page, you signify your acceptance of this Privacy
      Policy. If you do not agree with this policy, please refrain from using
      the website.
    </Div>
  )
}
