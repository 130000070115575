import { Routes, Route } from 'react-router-dom'

// Pages
// import EditPdfPage from 'components/pages/EditPdfPage/EditPdfPage';
import MainPage from 'components/pages/MainPage/MainPage'
import PasswordGeneratorPage from 'components/pages/PasswordGeneratorPage/PasswordGeneratorPage'
import BlogPage from 'components/pages/BlogPage/BlogPage'
import BlogPostPage from 'components/pages/BlogPostPage/BlogPostPage'
import PrivacyPolicyPage from 'components/pages/PrivacyPolicyPage/PrivacyPolicyPage'
import ContactUsPage from 'components/pages/ContactUsPage/ContactUsPage'

export const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BlogPage />} />
      <Route path="/conversion" element={<MainPage />} />
      <Route path="/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/password" element={<PasswordGeneratorPage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/blog/:id" element={<BlogPostPage />} />
      <Route path="*" element={<MainPage shouldShowPageNotFoundToast />} />
    </Routes>
  )
}
