import { useEffect } from 'react'

// Styles
import './MainPage.scss'

// Components
import MeasurementConverter from '../../molecules/MeasurementConverter/MeasurementConverter'
import MetaTags from '../../atoms/MetaTags/MetaTags'

// Hooks
import { useAtomize } from '../../../hooks/useAtomize'
import { useBoolean } from '../../../hooks/useBoolean'

// Data
import { meta } from './MainPageMetaData'

type Props = {
  shouldShowPageNotFoundToast?: boolean
}

export default function MainPage({ shouldShowPageNotFoundToast }: Props) {
  const { Div, Notification, Icon } = useAtomize()
  const {
    value: isNotificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useBoolean(false)

  useEffect(() => {
    if (shouldShowPageNotFoundToast) {
      setNotificationOpen()
    }
  }, [shouldShowPageNotFoundToast, setNotificationOpen])

  return (
    <>
      <MetaTags {...meta} />
      <Notification
        bg="danger100"
        textColor="danger800"
        isOpen={isNotificationOpen}
        onClose={setNotificationClosed}
        prefix={
          <Icon
            name="Close"
            color="danger800"
            size="18px"
            m={{ r: '0.5rem' }}
          />
        }
      >
        Page not found
      </Notification>
      <Div className="converter-wrapper">
        <Div className="converter-container">
          <MeasurementConverter />
          <Div className="converter-text">
            Welcome to UnitConversion.page - Your Ultimate Unit Conversion Tool!
            <br />
            <br />
            Are you tired of manually converting units for different
            measurements? Look no further! UnitConversion.page is your one-stop
            destination for hassle-free and accurate unit conversions.
            <br />
            <br />
            Why Choose UnitConversion.page?
            <br />
            <br />
            <ul>
              <li>
                Easy-to-Use Interface: Our user-friendly design ensures smooth
                navigation, making unit conversions a breeze.
              </li>
              <li>
                Comprehensive Conversion Options: From length and weight to
                temperature and volume, we cover a wide range of measurement
                units to cater to all your conversion needs.
              </li>
              <li>
                Precise and Reliable Results: Say goodbye to manual calculations
                and human errors. Our advanced algorithms deliver precise and
                reliable conversion results.
              </li>
              <li>
                Mobile-Friendly: Access UnitConversion.page on the go! Our
                website is fully optimized for mobile devices, ensuring a
                seamless experience on any screen size.
              </li>
              <li>
                Secure and Ad-Free: Your privacy is our priority. We guarantee a
                safe and ad-free environment, ensuring you can convert units
                without any distractions.
              </li>
            </ul>
            <br />
            <br />
            How to Use UnitConversion.page?
            <br />
            <br />
            <ol>
              <li>
                Select the "From" unit and "To" unit from the dropdown menus.
              </li>
              <li>Enter the value you want to convert in the input field.</li>
              <li>
                Click the "Convert" button to see the accurate conversion result
                instantly.
              </li>
            </ol>
            <br />
            <br />
            Join thousands of users who rely on UnitConversion.page for quick
            and precise unit conversions. Whether you're a student,
            professional, or simply need to convert units for day-to-day tasks,
            our tool is here to make your life easier.
            <br />
            <br />
            Start converting units with confidence now! Explore
            UnitConversion.page and simplify your measurement conversions today.
            <br />
            <br />
            Today, the International System of Units (SI) is the global standard
            of measurement, but it has not been fully adopted worldwide.
            <br />
            <br />
            As a result, other systems of measurement are still in use in some
            regions.
            <br />
            <br />
            Our goal is to provide you with a basic understanding of these
            systems and how they interact, as well as to offer a simple means of
            converting between them.
          </Div>
        </Div>
      </Div>
    </>
  )
}
