import { Link, BrowserRouter as Router } from 'react-router-dom'

// Hooks
import { useAtomize } from '../../../hooks/useAtomize'
import { useBoolean } from '../../../hooks/useBoolean'
import ScrollToTop from '../../../hooks/useScrollToTop'

// Components
import { MyRoutes } from 'MyRoutes'
import Footer from '../../molecules/Footer/Footer'
import Sidebar, {
  HamburgerButton,
  Overlay,
} from '../../molecules/SideBar/SideBar'

// Styles
import './MainLayout.scss'

const MainLayout = () => {
  const { Div } = useAtomize()
  const { value: active, toggle } = useBoolean(false)

  const handleSideBarClosed = () => {}

  return (
    <Router>
      <ScrollToTop />
      <Div d="flex" flexDir="column">
        <Overlay
          className={active ? 'overlay active' : 'overlay'}
          onClick={toggle}
        />
        <Div className="main-layout-header">
          <Div className="main-layout-header-logo">
            <Link to="/">
              <img
                className="avatar"
                src="/ms-icon-310x310.png"
                style={{ height: '40px', width: '40px' }}
                alt="logo"
              />
            </Link>
          </Div>
          <HamburgerButton isActive={active} onClick={toggle} />
        </Div>

        <Sidebar
          className={active ? 'active' : undefined}
          onClose={handleSideBarClosed}
        />
        <Div flexGrow="1">
          <Div className="main-layout">
            <MyRoutes />
          </Div>
        </Div>
        <Footer />
      </Div>
    </Router>
  )
}

export default MainLayout
