export const useAtomize = () => {
  const Atomize = require('atomize')
  const {
    StyleReset,
    Container,
    Row,
    Col,
    Div,
    iconPaths,
    Icon,
    Text,
    Button,
    Input,
    Textarea,
    Label,
    Checkbox,
    Radiobox,
    Switch,
    Image,
    Tag,
    Anchor,
    Collapse,
    Modal,
    SideDrawer,
    Notification,
    Dropdown,
    scrollTo,
    currentDevice,
    ThemeProvider,
  } = Atomize

  return {
    StyleReset,
    Container,
    Row,
    Col,
    Div,
    iconPaths,
    Icon,
    Text,
    Button,
    Input,
    Textarea,
    Label,
    Checkbox,
    Radiobox,
    Switch,
    Image,
    Tag,
    Anchor,
    Collapse,
    Modal,
    SideDrawer,
    Notification,
    Dropdown,
    scrollTo,
    currentDevice,
    ThemeProvider,
  }
}
