import React, { useCallback, useState } from 'react'
import Select, { ActionMeta, SingleValue, createFilter } from 'react-select'

// Hooks
import {
  useMeasurementUnits,
  useConvertUnits,
  Measurement,
  listCompatibleUnits,
} from './useConvertUnits'
import { useAtomize } from '../../../hooks/useAtomize'
import { useBoolean } from '../../../hooks/useBoolean'

// Styles
import './MeasurementConverter.scss'

const MeasurementConverter = () => {
  const { Div, Button, Input, Notification, Icon, Text } = useAtomize()
  const measurementUnits = useMeasurementUnits()
  const [fromUnit, setFromUnit] = useState('')
  const [toUnit, setToUnit] = useState('')
  const {
    value: isNotificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useBoolean(false)
  const [compatibleUnits, setCompatibleUnits] = useState<Measurement[]>([])
  const [value, setValue] = useState('')
  const [result, setResult] = useState(0)

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any' as const,
    trim: true,
  }

  const useHandleConversion = () => {
    const convertedValue = useConvertUnits(fromUnit, toUnit, parseFloat(value))
    setResult(convertedValue.result)
  }

  const handleOnchangeFromUnit = useCallback(
    (
      newValue: SingleValue<Measurement>,
      actionMeta: ActionMeta<Measurement>,
    ) => {
      if (newValue) {
        setToUnit('')
        setCompatibleUnits([])
        setFromUnit(newValue.value)
        const compatible = listCompatibleUnits(newValue.value)
        setCompatibleUnits(compatible)
        setValue('')
      }
    },
    [],
  )

  const handleOnchangeToUnit = useCallback(
    (
      newValue: SingleValue<Measurement>,
      actionMeta: ActionMeta<Measurement>,
    ) => {
      if (newValue) {
        setToUnit(newValue.value)
        setValue('')
      }
    },
    [],
  )

  const handleOnchangeValue = useCallback((e: any) => {
    setValue(e.target.value)
  }, [])

  const handleOnCopyValue = useCallback(() => {
    navigator.clipboard.writeText(result.toString())
    setNotificationOpen()
  }, [result, setNotificationOpen])

  return (
    <Div className="unit-converter">
      <Notification
        bg="success700"
        isOpen={isNotificationOpen}
        onClose={() => setNotificationClosed()}
        prefix={
          <Icon name="Success" color="white" size="18px" m={{ r: '0.5rem' }} />
        }
      >
        Copied to clipboard
      </Notification>
      <h2>Measurement Converter</h2>
      <Text
        tag="label"
        textSize="subheader"
        textColor="black700"
        m={{ t: '0.5rem' }}
      >
        From:
      </Text>
      <Select
        onChange={handleOnchangeFromUnit}
        options={measurementUnits}
        placeholder="From"
        aria-label="From"
        filterOption={createFilter(filterConfig)}
      />
      <Text
        tag="label"
        textSize="subheader"
        textColor="black700"
        m={{ t: '0.5rem' }}
      >
        To:
      </Text>
      <Select
        onChange={handleOnchangeToUnit}
        options={compatibleUnits}
        placeholder="To"
        aria-label="To"
        filterOption={createFilter(filterConfig)}
      />
      <Text
        tag="label"
        textSize="subheader"
        textColor="black700"
        m={{ t: '0.5rem' }}
      >
        Value to convert:
      </Text>
      <Input
        type="number"
        value={value}
        onChange={handleOnchangeValue}
        placeholder="Value"
        rounded={{ l: 'md' }}
        suffix={
          <Button
            onClick={useHandleConversion}
            bg="brand700"
            shadow="3"
            hoverShadow="3"
            disabled={!fromUnit || !toUnit || !value}
            rounded={{ r: 'md' }}
          >
            Convert
          </Button>
        }
      />
      <Div m={{ t: { xs: '2rem', md: '2rem', lg: '2rem' } }}>
        {result >= 1 && (
          <Input
            type="number"
            value={result}
            readOnly
            suffix={
              <Button
                pos="absolute"
                onClick={handleOnCopyValue}
                bg="brand700"
                hoverBg="brand900"
                w="3rem"
                top="0"
                right="0"
                rounded={{ r: 'md' }}
              >
                <Icon
                  name="Rename"
                  size="20px"
                  color="white"
                  cursor="pointer"
                />
              </Button>
            }
          />
        )}
      </Div>
    </Div>
  )
}

export default MeasurementConverter
