import { ReactEventHandler, useCallback, useEffect, useState } from 'react'
import clipboardCopy from 'clipboard-copy'

// Styles
import './PasswordGeneratorPage.scss'

// Hooks
import { useAtomize } from '../../../hooks/useAtomize'
import { useBoolean } from '../../../hooks/useBoolean'

// Utils
import { Score } from '../../../utils/password-score.js'
import { options } from '../../../utils/password-score-options'

// Components
import CustomAccordion from '../../molecules/Accordion/CustomAccordion'
import MetaTags from '../../atoms/MetaTags/MetaTags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

// Data
import { PasswordFAQ } from './data/faq.js'
import { PasswordGeneratorPageMetaData } from './PasswordGeneratorPageMetaData'

type Props = {
  shouldShowPageNotFoundToast?: boolean
}

const UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const LOWERCASE = 'abcdefghijklmnopqrstuvwxyz'
const NUMBERS = '0123456789'
const SPECIAL = '!@#$%^&*()-_=+'

function PasswordFaq() {
  const { Div, Text } = useAtomize()
  return (
    <Div className="password-faq-wrapper">
      <Text tag="h2">Frequently asked questions</Text>
      <CustomAccordion elements={PasswordFAQ} />
    </Div>
  )
}

function PasswordPageContent() {
  const { Div, Text } = useAtomize()
  return (
    <Div className="password-page-content-wrapper">
      <Text tag="h2">Make sure you have a strong password</Text>
      <Text tag="p">
        Vulnerable or compromised passwords remain a significant cause of data
        breaches, a concern highlighted in numerous reports year after year.
        However, you have the power to stand out as an exception. Enhance your
        data security by adopting dependable and safeguarded passwords. It takes
        just this simple step to lower the risk of data theft. Our robust
        password generator is here to assist you and your business in taking
        that crucial first stride towards fortifying your online accounts with
        stronger and more secure passwords.
      </Text>
    </Div>
  )
}

export default function PasswordGeneratorPage({
  shouldShowPageNotFoundToast,
}: Props) {
  const { Div, Notification, Icon, Text, Input, Button } = useAtomize()
  const [password, setPassword] = useState('')
  const [passwordLength, setPasswordLength] = useState(12)
  const [timeToCrack, setTimeToCrack] = useState('')
  const [cores] = useState(8)
  const [useSpecialCharacters, setUseSpecialCharacters] = useState(true)
  const [useNumbers, setUseNumbers] = useState(true)
  const [useLowercase, setUseLowercase] = useState(true)
  const [useUppercase, setUseUppercase] = useState(true)
  const [isOptionsValid, setIsOptionsValid] = useState(false)
  const {
    value: isNotificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useBoolean(false)

  const calculateTimeToCrack = useCallback(
    (result: string) => {
      const score = new Score(result)
      score.calculateEntropyScore(options, false)
      const entropy =
        Math.round((score.cache as { entropy: number }).entropy * 100) / 100
      let timeToCrack = score.calculateAverageTimeToCrack(entropy, cores)
      let unit = 'seconds'
      if (timeToCrack > 60) {
        timeToCrack /= 60
        unit = 'minutes'

        if (timeToCrack > 60) {
          timeToCrack /= 60
          unit = 'hours'

          if (timeToCrack > 24) {
            timeToCrack /= 24
            unit = 'days'

            if (timeToCrack > 365) {
              timeToCrack /= 365
              unit = 'years'

              if (timeToCrack > 1000) {
                timeToCrack /= 1000
                unit = 'millenia'
              }
            }
          }
        }
      }
      setTimeToCrack(
        'Cracked on average within ' +
          timeToCrack.toFixed(2) +
          ' ' +
          unit +
          ' using ' +
          cores +
          ' parallel cores.\nPassword Entropy: ' +
          entropy,
      )
    },
    [cores],
  )

  const generatePassword = () => {
    const characters = `${useUppercase ? UPPERCASE : ''}${
      useLowercase ? LOWERCASE : ''
    }${useNumbers ? NUMBERS : ''}${useSpecialCharacters ? SPECIAL : ''}`
    let result = ''
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      result += characters[randomIndex]
    }
    setPassword(result)
    calculateTimeToCrack(result)
  }

  const handleCopyToClipboard = (e: ReactEventHandler) => {
    // setCopyButtonDisabled(true)
    clipboardCopy(password)
    setNotificationOpen()
  }

  useEffect(() => {
    if (shouldShowPageNotFoundToast) {
      setNotificationOpen()
    }
  }, [shouldShowPageNotFoundToast, setNotificationOpen])

  useEffect(() => {
    if (
      !useUppercase &&
      !useLowercase &&
      !useNumbers &&
      !useSpecialCharacters
    ) {
      setIsOptionsValid(false)
    }
    if (useUppercase || useLowercase || useNumbers || useSpecialCharacters) {
      setIsOptionsValid(true)
    }
  }, [useUppercase, useLowercase, useNumbers, useSpecialCharacters])

  return (
    <>
      <MetaTags {...PasswordGeneratorPageMetaData} />
      <Notification
        bg="success700"
        isOpen={isNotificationOpen}
        onClose={() => setNotificationClosed()}
        prefix={
          <Icon name="Success" color="white" size="18px" m={{ r: '0.5rem' }} />
        }
      >
        Copied to clipboard
      </Notification>
      <Div className="generator-wrapper">
        <Div className="generator-container">
          <Text tag="h1">Password Generator</Text>
          <Text tag="p" m={{ b: '1rem' }}>
            Need a strong password? Try our Password Generator to create complex
            passwords that will keep your information safe.
          </Text>
          <Div className="generator-text">
            {password && (
              <Div>
                <Input
                  value={password}
                  className="generated-password"
                  readOnly
                />
              </Div>
            )}
            {timeToCrack && (
              <Div>
                <Text
                  textSize="subheader"
                  textColor="black700"
                  m={{ b: '0.5rem' }}
                >
                  {timeToCrack}
                </Text>
              </Div>
            )}
            <Div
              d="flex"
              m={{ y: '1rem' }}
              className="generator-options-buttons"
            >
              <Button
                onClick={generatePassword}
                bg="brand700"
                shadow="3"
                hoverShadow="3"
                m={{ r: '1rem' }}
                disabled={!isOptionsValid}
                prefix={
                  <FontAwesomeIcon icon={icon({ name: 'user-secret' })} />
                }
              >
                Generate Password
              </Button>
              <Button
                onClick={handleCopyToClipboard}
                bg="success600"
                shadow="3"
                hoverShadow="3"
                disabled={!password}
                prefix={<FontAwesomeIcon icon={icon({ name: 'copy' })} />}
              >
                Copy to Clipboard
              </Button>
            </Div>
            <Div className="generator-options">
              <Text tag="span">Number of characters: {passwordLength}</Text>
              <Input
                type="range"
                min="4"
                max="30"
                step="1"
                border="none"
                bg="transparent"
                p="0"
                value={passwordLength}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPasswordLength(parseInt(e.target.value))
                }
              />
              <Div
                d="flex"
                justify="space-between"
                className="generator-options-checkboxes"
              >
                <Div
                  className="generator-option"
                  onClick={() => setUseUppercase(!useUppercase)}
                >
                  <Input
                    type="checkbox"
                    onChange={() => {}}
                    checked={useUppercase}
                  />
                  <Text tag="span">Uppercase</Text>
                </Div>
                <Div
                  className="generator-option"
                  onClick={() => setUseLowercase(!useLowercase)}
                >
                  <Input
                    type="checkbox"
                    onChange={() => {}}
                    checked={useLowercase}
                    bg="white"
                  />
                  <Text tag="span">Lowercase</Text>
                </Div>
                <Div
                  className="generator-option"
                  onClick={() => setUseNumbers(!useNumbers)}
                >
                  <Input
                    type="checkbox"
                    onChange={() => {}}
                    checked={useNumbers}
                  />
                  <Text tag="span">Numbers</Text>
                </Div>
                <Div
                  className="generator-option"
                  onClick={() => setUseSpecialCharacters(!useSpecialCharacters)}
                >
                  <Input
                    type="checkbox"
                    onChange={() => {}}
                    checked={useSpecialCharacters}
                  />
                  <Text tag="span">Special Characters</Text>
                </Div>
              </Div>
            </Div>
            <PasswordPageContent />
            <PasswordFaq />
          </Div>
        </Div>
      </Div>
    </>
  )
}
