import { Helmet } from 'react-helmet'

type Props = {
  title: string
  description: string
  url: string
  type: string
}

export default function MetaTags({ title, description, url, type }: Props) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:type" property="og:type" content={type} />
      <meta name="og:title" property="og:title" content={title} />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta
        name="og:image"
        property="og:image"
        content="%PUBLIC_URL%/android-icon-192x192.png"
      />
      <meta name="og:url" property="og:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}
