import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { SpeedInsights } from '@vercel/speed-insights/react'
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'

// Styles
import './index.scss'
import { Provider as StyletronProvider, DebugEngine } from 'styletron-react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { theme } from './styles/atomizeOverrides'

// Pages
import MainLayout from './components/layouts/MainLayout/MainLayout'

// Analytics
import { Analytics } from '@vercel/analytics/react'
import TagManager from 'react-gtm-module'

const Atomize = require('atomize')

const debug = process.env.NODE_ENV === 'production' ? void 0 : new DebugEngine()

const engine = new Styletron()

const tagManagerArgs = {
  gtmId: 'G-18Z1FLLLFQ',
}

TagManager.initialize(tagManagerArgs)

const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/?access_token=${process.env.REACT_APP_CONTENTFUL_CONTENT_DELIVERY_API_KEY}`,
  cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const startApp = async () => {
  if (!isProduction()) {
    await tryLoadAndStartRecorder({
      projectId: 'Mcygp26O2jFTS0yCc4Dqoc3aYeSrUXFIoyLDZ0ci',
      isProduction: false,
    })
  }

  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <SpeedInsights />
        <StyletronProvider value={engine} debug={debug} debugAfterHydration>
          <Atomize.ThemeProvider theme={theme}>
            <Analytics />
            <MainLayout />
          </Atomize.ThemeProvider>
        </StyletronProvider>
      </ApolloProvider>
    </React.StrictMode>,
  )
}

function isProduction() {
  return window.location.hostname.indexOf('unitconversion.page') > -1
}

startApp()
reportWebVitals()
