import React, { useState, useEffect } from 'react'

// Styles
import './BlogPage.scss'

// Components
import MetaTags from '../../atoms/MetaTags/MetaTags'
import { BlogPostCard } from './BlogPostCard/BlogPostCard'
import { Spinner } from '../../atoms/Spinner/Spinner'

// Hooks
import { useAtomize } from '../../../hooks/useAtomize'
import { useBoolean } from '../../../hooks/useBoolean'

// Data
import { BlogPageMetaData } from './BlogPageMetaData'

const query = `
{
  blogPageCollection(order: [sys_firstPublishedAt_DESC]) {
    items {
      title
      sys {
        id
        firstPublishedAt
      }
      body
      image {
        url
      }
    }
  }
}
`

interface BlogPost {
  title: string
  sys: {
    id: string
    firstPublishedAt: Date
  }
  body: string
  image: {
    url: string
  }
}

export const BlogPage = () => {
  const { Div, Notification, Icon, Text } = useAtomize()
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([])
  const {
    value: isNotificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useBoolean(false)

  useEffect(() => {
    window
      .fetch(
        `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_CONTENT_DELIVERY_API_KEY}`,
          },
          body: JSON.stringify({ query }),
        },
      )
      .then(response => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors)
        }
        setBlogPosts(data.blogPageCollection.items)
      })
  }, [])

  if (!blogPosts) {
    return (
      <Div className="spinner-centered blog-wrapper">
        <Spinner />
      </Div>
    )
  }

  return (
    <>
      <MetaTags {...BlogPageMetaData} />
      <Notification
        bg="danger100"
        textColor="danger800"
        isOpen={isNotificationOpen}
        onClose={setNotificationClosed}
        prefix={
          <Icon
            name="Close"
            color="danger800"
            size="18px"
            m={{ r: '0.5rem' }}
          />
        }
      >
        Page not found
      </Notification>
      <Div className="blog-page-container">
        <Div className="blog-page-header">
          <Text tag="h1">Blog</Text>
        </Div>
        <Div className="blog-wrapper">
          {blogPosts.map((post, index) => (
            <BlogPostCard
              key={index}
              title={post.title}
              body={post.body}
              image={post.image.url}
              id={post.sys.id}
              date={post.sys.firstPublishedAt}
            />
          ))}
        </Div>
      </Div>
    </>
  )
}

export default BlogPage
