import React from 'react'
import { Link } from 'react-router-dom'

// Hooks
import { useAtomize } from '../../../hooks/useAtomize'

// Styles
import './Footer.scss'

const Footer = () => {
  const { Div, Container, Text } = useAtomize()
  return (
    <Div bg="#3D3D3D" p={{ t: '0.5rem', b: '0.5rem' }}>
      <Container p="0">
        <Div
          d="flex"
          flexDir="row"
          justify="center"
          m={{ x: 'auto' }}
          w={{ sm: '100%', md: '50%', lg: '50%' }}
        >
          <Link to="/" style={{ marginRight: '1rem' }} className="footer-link">
            <Text textColor="white" d="inline-block">
              Home
            </Text>
          </Link>
          <Link
            to="/privacy"
            style={{ marginRight: '1rem' }}
            className="footer-link"
          >
            <Text textColor="white" d="inline-block">
              Privacy Policy
            </Text>
          </Link>
          <Link
            to="/terms-of-use"
            style={{ marginRight: '1rem' }}
            className="footer-link"
          >
            <Text textColor="white" d="inline-block">
              Terms of Use
            </Text>
          </Link>
          <Link
            to="/contact-us"
            style={{ textDecoration: 'none' }}
            className="footer-link"
          >
            <Text textColor="white" d="inline-block">
              Contact Us
            </Text>
          </Link>
        </Div>
        <Text
          textColor="white"
          textSize="caption"
          textAlign="center"
          m={{ t: '0.5rem', b: 0 }}
        >
          © {new Date().getFullYear()}{' '}
          <Link to="/" style={{ color: 'white' }}>
            UnitConversion.page
          </Link>
          . All rights reserved.
        </Text>
      </Container>
    </Div>
  )
}

export default Footer
