import { useEffect, useState } from 'react'

export type Measurement = {
  label: string
  value: string
}

export type ConvertedValue = {
  result: number
  unit: string
  describe: DescribeObject
}

export type DescribeObject = {
  abbr: string
  measure: string
  system: string
  singular: string
  plural: string
}

export const useMeasurementUnits = (): Measurement[] => {
  const [measurementUnits, setMeasurementUnits] = useState<Measurement[]>([])

  useEffect(() => {
    var convert = require('convert-units')
    const units = convert().list()
    const measurementUnits = units.map((unit: any) => ({
      label: unit.plural + ' - ' + unit.abbr,
      value: unit.abbr,
    }))
    setMeasurementUnits(measurementUnits)
  }, [])

  return measurementUnits
}

export const useConvertUnits = (
  from: string,
  to: string,
  value: number,
): ConvertedValue => {
  var convert = require('convert-units')
  return {
    result: convert(value).from(from).to(to),
    unit: to,
    describe: convert().describe(to),
  }
}

export const listCompatibleUnits = (from: string): Measurement[] => {
  const convert = require('convert-units')
  const units = convert().from(from).possibilities()
  const unitsDescriptions = units.map((unit: any) => convert().describe(unit))
  const measurementUnits = unitsDescriptions.map((unit: any) => ({
    label: unit.plural + ' - ' + unit.abbr,
    value: unit.abbr,
  }))
  return measurementUnits
}
