import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

// Styles
import './CustomAccordion.scss'

type Props = {
  elements: Array<{
    title: string
    content: string
  }>
}

export default function CustomAccordion({ elements }: Props) {
  return (
    <Accordion>
      {elements.map((element, index) => (
        <AccordionItem key={index}>
          <AccordionItemHeading>
            <AccordionItemButton>{element.title}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{element.content}</p>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
