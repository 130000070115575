import { Link } from 'react-router-dom'

// Styles
import './BlogPostCard.scss'

type Props = {
  title: string
  body: string
  image: string
  id: string
  date: Date
}

export const BlogPostCard = ({ title, body, image, id, date }: Props) => {
  const formattedDate = new Date(date).toLocaleDateString('en-UK')
  return (
    <Link to={`/blog/${id}`}>
      <div className="card">
        <img src={image} alt="Blog post" />
        <div className="card-body">
          <h2>{title}</h2>
          <p>{body}</p>
          <h5>{formattedDate}</h5>
        </div>
      </div>
    </Link>
  )
}
